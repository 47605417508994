import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Rope Climbs`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`Ville                                                                
                              `}<em parentName="strong">{`*`}{`East`}</em></strong></p>
    <p>{`Buy in: 50 Calorie Air Dynne                                            
            `}<em parentName="p">{` `}{`*`}{`Buy in:  30 Calorie Ski Erg`}</em></p>
    <p>{`4 Rounds of:`}</p>
    <p>{`1-Rope Climb to 15′`}</p>
    <p>{`2-50ft Sled Drags (Pull Sled Backwards inside, using straps) `}<em parentName="p">{` `}{`*`}{`Pull
Dog Sled Backwards by handles`}</em>{` (Sled +90`}{`#`}{`/+45`}{`#`}{` at both locations)`}</p>
    <p>{`3-Wall Walks`}</p>
    <p>{`4-Box Jumps (30/24″)`}</p>
    <p>{`5-Hang Power Cleans (155/105)`}</p>
    <p>{`Cash Out: 50 Double Unders`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our Free Workout at 8:00 & 9:00am at The Ville and
10:00 & 11:00am at East so bring a friend!`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this year on November 4th!  Get your teams together now.  More
info to come. Truegritcomp.wordpress.com.***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      